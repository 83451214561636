import clsx from 'clsx'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

const white = '#ffffff'
const firstColor = '#D65A09'
const secondColor = '#E5004B'
const proportionFirtst = 0.6
const proportionSecond = 0.4

const styles = theme => ({
  root: {
    alignItems: 'baseline',
    display: 'flex',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1.5,
    paddingBottom: '20px',
    textAlign: 'center',
    transition: 'height 0.1s linear',
    width: '47px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '53px',
      minWidth: '53px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '64px',
    },
    '& span': {flex: 1, fontWeight: 'bolder', marginTop: 'auto'},
  },
  first: {
    backgroundColor: firstColor,
    color: white,
    borderRadius: '4px 4px 0px 0px',
  },
  second: {
    backgroundColor: secondColor,
    color: white,
    borderRadius: '0px 0px 4px 4px',
  },
  typography: {
    textAlign: 'center',
    font: 'normal normal bold 11px/12px Roboto',
    letterSpacing: '0.08px',
  },
  columnValue: {
    display: 'block',
    textAlign: 'center',
    marginBottom: '8px',
  },
})

function getColumnStyles(value, baseValue, baseHeight) {
  const decimal = value * baseValue
  return {
    height: `${decimal * baseHeight}px`,
    maxHeight: `${decimal * 10}px`,
    minHeight: `${decimal * baseHeight}px`,
    width: '50px',
  }
}

const PureColumnDouble = ({
  baseHeight,
  classes,
  className,
  valueFirst,
  valueSecond,
  valueSum,
  valueBase,
}) => (
  <>
    <Typography className={clsx(classes.typography, classes.columnValue)} component="span">
      <span>{valueSum}</span>
    </Typography>
    <Typography variant="srOnly"> (</Typography>
    <Typography
      className={clsx(classes.root, classes.typography, classes.first, className)}
      component="span"
      style={getColumnStyles(valueBase, proportionFirtst, baseHeight)}>
      <span>{valueFirst}</span>
    </Typography>
    <Typography
      className={clsx(classes.root, classes.typography, classes.second, className)}
      component="span"
      style={getColumnStyles(valueBase, proportionSecond, baseHeight)}>
      <span>{valueSecond}</span>
    </Typography>
    <Typography variant="srOnly">)</Typography>
  </>
)

const ColumnDouble = withStyles(styles)(PureColumnDouble)

export default ColumnDouble
