import React, {useState} from 'react'
import GridContainer from '@naturacosmeticos/natds-web/dist/Components/GridContainer'
import GridItem from '@naturacosmeticos/natds-web/dist/Components/GridItem'
import withStyles from '@material-ui/core/styles/withStyles'
import Hidden from '@material-ui/core/Hidden'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import styles from './EarningsCalculator.styles'
import EarningsCalculatorPercentage from './EarningsCalculatorPercentage'
import EarningsCalculatorCardDesktop from './EarningsCalculatorCardDesktop'
import EarningsCalculatorResults from './EarningsCalculatorResults'
import EarningsCalculatorResultsMobile from './EarningsCalculatorResultsMobile'
import EarningsCalculatorCardMobileLessDescription from './EarningsCalculatorCardMobileLessDescription'
import EarningsCalculatorCardMobile from './EarningsCalculatorCardMobile'
import Grid from '@material-ui/core/Grid'
import ProductsNatura from '../../../resources/assets/images/earningscalculator/produtos-natura-2x.png'
import ProductsNaturaAvon from '../../../resources/assets/images/earningscalculator/produtos-natura-avon-2x.png'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const dataCalculationCard = [
  {
    altImage: 'Produtos da Natura e Avon',
    image: ProductsNaturaAvon,
    imageFull: true,
    textImage: 'Imagem e valores meramente ilustrativos exemplificando os seus ganhos ao enviar um pedido mínimo de cada marca.',
  },
  {
    altImage: 'Produtos da Natura',
    image: ProductsNatura,
    imageFull: false,
    textImage: 'O Espaço Digital está disponível apenas para a venda de produtos Natura.',
  },
]

const EarningsCalculator = ({classes}) => {
  const [tabActive, setTabActive] = useState(0)
  const hidden = useMediaQuery(theme => theme.breakpoints.up('md'))
  return (
    <Paper className={classes.containerPaper}>
      <Tabs
        value={tabActive}
        onChange={(e, index) => {
          setTabActive(index)
        }}
        indicatorColor="primary"
        textColor="primary"
        classes={{
          root: classes.customTabs,
          indicator: classes.customTabIndicator,
        }}
        centered>
        <Tab
          id="f2f-sales-clickable"
          data-gtm-category="landing_ganhos_como_consultora"
          data-gtm-main-action="click"
          data-gtm-label="Vendas Presenciais"
          data-gtm-identifier="button_text"
          data-gtm-category-ga4="landing_lucros_e_ganhos"
          data-gtm-main-action-ga4="click"
          data-gtm-label-ga4="Vendas Presenciais"
          data-testid="earnings-calculator-facetoface-sales"
          label="Vendas Presenciais"
          classes={{root: classes.customTab, selected: classes.tabActive}}
        />
        <Tab
          id="online-sales-clickable"
          data-gtm-category="landing_ganhos_como_consultora"
          data-gtm-main-action="click"
          data-gtm-label="Vendas Pela Internet"
          data-gtm-identifier="button_text"
          data-gtm-category-ga4="landing_lucros_e_ganhos"
          data-gtm-main-action-ga4="click"
          data-gtm-label-ga4="Consultoria De Beleza Online"
          data-testid="earnings-calculator-internet-sales"
          label="Consultoria de Beleza Online"
          classes={{root: classes.customTab, selected: classes.tabActive}}
        />
      </Tabs>
      <Divider className={classes.earningsTabDivider} />
      {hidden ? 
      <div>

      <div className={classes.containerDesktop}>
        <Grid item>
          <EarningsCalculatorCardMobileLessDescription data={dataCalculationCard[tabActive]} tabActive={tabActive} />
        </Grid>
        <Grid item>
          <EarningsCalculatorResults tabActive={tabActive} />
        </Grid>
      </div>
      {tabActive === 0 ? <EarningsCalculatorPercentage /> : null}
      </div>
      : 
      <GridContainer direction="column">
          <GridItem>
            <EarningsCalculatorCardMobileLessDescription data={dataCalculationCard[tabActive]} />
          </GridItem>
          <Divider className={classes.earningsTabDivider} />
          <GridItem>
            <EarningsCalculatorResultsMobile tabActive={tabActive} />
          </GridItem>
        </GridContainer>
      }
    </Paper>
  )
}

export default withStyles(styles)(EarningsCalculator)
