import React from 'react'
import {graphql} from 'gatsby'
import HomeTemplate from '../templates'
import HeroImage from '../components/Hero/HeroImage'
import SEO from '../components/SEO'
import {schemaOrgJSONLD} from '../components/FAQ/google-rich-results'

/**
 * @property allFAQItems
 */
export const query = graphql`
  query HomePageQuery {
    allFAQItems: allMdx(
      filter: {fileAbsolutePath: {regex: "/faq/"}}
      sort: {fields: frontmatter___order, order: ASC}
    ) {
      edges {
        node {
          id
          body
          excerpt
          frontmatter {
            title
          }
        }
      }
    }
  }
`

const Index = ({data, ...props}) => (
  <>
    <SEO article={false} title="Seja Consultora">
      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
    </SEO>
    <HomeTemplate {...props} FAQData={data && data.allFAQItems} heroImage={<HeroImage />} />
  </>
)

export default Index
