import React from 'react'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'
import naturaLogo from '../../../resources/assets/images/earningscalculator/natura-logo-2x.png'
import avonLogo from '../../../resources/assets/images/earningscalculator/avon-logo-2x.png'

const values = [
  {
    seed: '20%',
    bronze: '30%',
    silver: '30%',
    gold: '32%',
    diamond: '35%',
    color: 'natura',
    logo: naturaLogo,
  },
  {
    seed: '20%',
    bronze: '30%',
    silver: '35%',
    gold: '38%',
    diamond: '38%',
    color: 'avon',
    logo: avonLogo,
  },
]

const styles = theme => ({
  containerColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '16px',
  },
  containerRow: {
    display: 'flex',
  },
  item: {
    height: '30px',
    width: '94px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 1200px)': {
      width: '84px',
    },
    '@media (max-width: 1024px)': {
      width: '72px',
    },
  },
  avon: {
    color: '#E5004B',
  },
  natura: {
    color: '#D65A09',
    boxShadow: '0px 1px 0px #BBBBBB',
  },
  itemBorder: {
    borderLeft: '1px solid #BBBBBB',
  },
  image: {
    width: '32px',
    height: '32px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  text: {
    marginRight: '24px',
    font: 'normal normal bold 16px/24px Roboto',
    letterSpacing: '0.51px',
    color: '#333333',
  },
})

const PureEarningsCalculatorPercentage = ({classes, tabActive}) => (
  <div className={classes.container}>
    <div className={classes.text}>% de ganhos:</div>
    <div className={classes.containerColumn}>
      {values.map(company => (
        <div className={clsx(classes.containerRow, classes[company.color])}>
          <img src={company.logo} alt="logo da marca" className={classes.image} />
          <div className={classes.item}>{company.seed}</div>
          <div className={clsx(classes.item, classes.itemBorder)}>{company.bronze}</div>
          <div className={clsx(classes.item, classes.itemBorder)}>{company.silver}</div>
          <div className={clsx(classes.item, classes.itemBorder)}>{company.gold}</div>
          <div className={clsx(classes.item, classes.itemBorder)}>{company.diamond}</div>
        </div>
      ))}
    </div>
  </div>
)

const EarningsCalculatorPercentage = withStyles(styles)(PureEarningsCalculatorPercentage)

export default EarningsCalculatorPercentage
