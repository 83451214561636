import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import ColumnDouble from './ColumnDouble'

const seedColor = '#7c6b58'
const bronzeColor = '#c47f5b'
const silverColor = '#9d9d9c'
const goldColor = '#d9b85f'
const diamondColor = '#afc2ce'
const white = '#ffffff'

const styles = theme => ({
  root: {
    marginTop: '20px',
    '@media (max-width: 319px)': {
      overflowX: 'scroll',
      width: '299px',
    },
  },
  columnText: {
    textAlign: 'left',
    font: 'normal normal bold 12px/18px Roboto',
    letterSpacing: '1px',
    color: '#000000',
  },
  gridItem: {
    padding: '0.5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {maxWidth: '60px'},
  },
})

const PureEarningsChartDouble = ({baseHeight, caption, classes, className, values}) => (
  <figure className={clsx(className, classes.root)}>
    <Typography component="figcaption" variant="srOnly">
      {caption}
    </Typography>
    <Grid
      container
      alignItems="flex-end"
      component="ul"
      direction="row"
      justify="space-evenly"
      spacing={1}>
      <Grid
        data-testid="earnings-calculator-chart-seed"
        className={clsx(classes.gridItem)}
        component="li"
        xs>
        <ColumnDouble
          valueSum={values.seedTotal}
          valueBase={values.seed}
          baseHeight={baseHeight}
          valueFirst={values.seedFirst}
          valueSecond={values.seedSecond}
        />
        <Typography component="span" variant="srOnly">
          {' '}
          para{' '}
        </Typography>
        <Typography className={classes.columnText} component="span">
          Semente
        </Typography>
        <Typography component="span" variant="srOnly">
          ;
        </Typography>
      </Grid>
      <Grid className={clsx(classes.gridItem)} component="li" xs>
        <ColumnDouble
          valueSum={values.bronzeTotal}
          valueBase={values.bronze}
          baseHeight={baseHeight}
          valueFirst={values.bronzeFirst}
          valueSecond={values.bronzeSecond}
        />
        <Typography component="span" variant="srOnly">
          {' '}
          para{' '}
        </Typography>
        <Typography className={classes.columnText} component="span">
          Bronze
        </Typography>
        <Typography component="span" variant="srOnly">
          ;
        </Typography>
      </Grid>
      <Grid className={clsx(classes.gridItem)} component="li" xs>
        <ColumnDouble
          valueSum={values.silverTotal}
          valueBase={values.silver}
          baseHeight={baseHeight}
          valueFirst={values.silverFirst}
          valueSecond={values.silverSecond}
        />
        <Typography component="span" variant="srOnly">
          {' '}
          para{' '}
        </Typography>
        <Typography className={classes.columnText} component="span">
          Prata
        </Typography>
        <Typography component="span" variant="srOnly">
          ;
        </Typography>
      </Grid>
      <Grid className={clsx(classes.gridItem)} component="li" xs>
        <ColumnDouble
          valueSum={values.goldTotal}
          valueBase={values.gold}
          baseHeight={baseHeight}
          valueFirst={values.goldFirst}
          valueSecond={values.goldSecond}
        />
        <Typography component="span" variant="srOnly">
          {' '}
          para{' '}
        </Typography>
        <Typography className={classes.columnText} component="span">
          Ouro
        </Typography>
        <Typography component="span" variant="srOnly">
          ;
        </Typography>
      </Grid>
      <Grid className={clsx(classes.gridItem)} component="li" xs>
        <ColumnDouble
          valueSum={values.diamondTotal}
          valueBase={values.diamond}
          baseHeight={baseHeight}
          valueFirst={values.diamondFirst}
          valueSecond={values.diamondSecond}
        />
        <Typography component="span" variant="srOnly">
          {' '}
          para{' '}
        </Typography>
        <Typography className={classes.columnText} component="span">
          Diamante
        </Typography>
        <Typography component="span" variant="srOnly">
          .
        </Typography>
      </Grid>
    </Grid>
  </figure>
)

const EarningsChartDouble = withStyles(styles)(PureEarningsChartDouble)

export default EarningsChartDouble
