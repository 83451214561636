import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from './Hero.styles'

import Container from '@material-ui/core/Container'
import GridContainer from '@naturacosmeticos/natds-web/dist/Components/GridContainer'
import GridItem from '@naturacosmeticos/natds-web/dist/Components/GridItem'
import HeroCard from './HeroCard'
import Hidden from '@material-ui/core/Hidden'
import RegisterCTA from '../RegisterCTA'
import Typography from '@material-ui/core/Typography'

const Hero = ({classes, heroImage, ...props}) => {
  const callToActionButtonText = 'CADASTRE-SE'

  return (
    <section {...props}>
      <Hidden implementation="css" smDown>
        <Container component="article">
          <GridContainer className={classes.container} alignItems="center" justify="center">
            <GridItem md={8} lg={8}>
              {heroImage}
            </GridItem>
            <GridItem md={4} lg={4}>
              <HeroCard />
            </GridItem>
          </GridContainer>
        </Container>
      </Hidden>
      <Hidden implementation="css" mdUp>
        <GridContainer direction="column">
          <GridItem sm={12}>
            <div className={classes.heroImageOverlay}>{heroImage}</div>
            <Typography className={classes.heroCardHeader} component="h2" variant="h5">
              Transforme sua vida com a Consultoria de Beleza
            </Typography>
          </GridItem>
          <GridItem sm={12} className={classes.heroMobileDescriptionBlock}>
            <Typography className={classes.heroCardDescription} component="p" variant="h6">
              Ganhe dinheiro vendendo produtos que voce ama!
            </Typography>
            <RegisterCTA
              id="hero-desktop-cta-button"
              aria-label="Cadastrar"
              className={classes.heroButton}
              data-gtm-category="landing_hero"
              data-gtm-main-action="click"
              data-gtm-label={callToActionButtonText}
              data-gtm-category-ga4="landing_hero"
              data-gtm-main-action-ga4="click"
              data-gtm-label-ga4={callToActionButtonText}
              data-testid="hero-register-cta-button-mobile"
              label={callToActionButtonText}
            />
          </GridItem>
        </GridContainer>
      </Hidden>
    </section>
  )
}

export default withStyles(styles)(Hero)
