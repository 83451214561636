import clsx from 'clsx'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  root: {
    alignItems: 'baseline',
    borderRadius: '4px',
    display: 'flex',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1.5,
    margin: '8px auto',
    paddingBottom: '20px',
    textAlign: 'center',
    transition: 'height 0.1s linear',
    width: '47px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '53px',
      minWidth: '53px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '64px',
    },
    '& span': {flex: 1, fontWeight: 'bolder', marginTop: 'auto'},
  },
  typography: {
    textAlign: 'center',
    font: 'normal normal bold 11px/12px Roboto',
    letterSpacing: '0.08px',
  },
  columnValue: {
    display: 'block',
    textAlign: 'center',
    font: 'normal normal bold 12px/18px Roboto',
    letterSpacing: '1.92px',
  },
})

function getColumnStyles(value, baseValue, baseHeight) {
  if (baseValue === 0) {
    return null
  }
  const decimal = value / baseValue
  return {
    height: `${decimal * baseHeight}px`,
    maxHeight: `${decimal * 85}px`,
    minHeight: `${decimal * baseHeight}px`,
  }
}

function toCurrency(value) {
  return value
    .toFixed(2)
    .toString()
    .replace('.', ',')
}

const PureColumn = ({baseAmount, baseHeight, baseValue, classes, className, value}) => (
  <>
    <Typography className={clsx(classes.typography)} component="span">
      <small aria-label="Reais">R$&nbsp;</small>
      {toCurrency(baseAmount * value / 100)}
    </Typography>
    <Typography variant="srOnly"> (</Typography>
    <Typography
      className={clsx(classes.root, classes.typography, className)}
      component="span"
      style={getColumnStyles(value, baseValue, baseHeight)}>
      <span className={classes.columnValue}>{value}%</span>
    </Typography>
    <Typography variant="srOnly">)</Typography>
  </>
)

const Column = withStyles(styles)(PureColumn)

export default Column
