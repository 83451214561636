import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Column from './Column'
import clsx from 'clsx'

const seedColor = '#B7003C'
const bronzeColor = '#BC5214'
const silverColor = '#9E9794'
const goldColor = '#DDAB00'
const diamondColor = '#8F74E3'
const white = '#ffffff'
const highEmphasis = '#333333'
const black = '#000000'
const graphColor = '#D65A09'

const styles = theme => ({
  root: {
    marginTop: '20px',
    '@media (max-width: 319px)': {
      overflowX: 'scroll',
      width: '299px',
    },
  },
  columnText: {
    display: 'block',
    fontSize: '14px',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {fontSize: '16px'},
  },
  gridItem: {[theme.breakpoints.up('sm')]: {maxWidth: '60px'}},
  seedColumn: {backgroundColor: graphColor, color: white},
  seedColumnText: {color: black},
  bronzeColumn: {backgroundColor: graphColor, color: white},
  bronzeColumnText: {color: black},
  silverColumn: {backgroundColor: graphColor, color: white},
  silverColumnText: {color: black},
  goldColumn: {backgroundColor: graphColor, color: white},
  goldColumnText: {color: black},
  diamondColumn: {backgroundColor: graphColor, color: white},
  diamondColumnText: {color: black},
})

const PureEarningsChart = ({baseAmount, baseHeight, caption, classes, className, values}) => (
  <figure className={clsx(className, classes.root)}>
    <Typography component="figcaption" variant="srOnly">
      {caption}
    </Typography>
    <Grid
      container
      alignItems="flex-end"
      component="ul"
      direction="row"
      justify="space-evenly"
      spacing={1}>
      <Grid
        item
        data-testid="earnings-calculator-chart-seed"
        className={clsx(classes.gridItem)}
        component="li"
        xs>
        <Column
          baseAmount={baseAmount}
          baseHeight={baseHeight}
          baseValue={values.diamond}
          className={classes.seedColumn}
          value={values.seed}
        />
        <Typography component="span" variant="srOnly">
          {' '}
          para{' '}
        </Typography>
        <Typography className={clsx(classes.columnText, classes.seedColumnText)} component="span">
          Semente
        </Typography>
        <Typography component="span" variant="srOnly">
          ;
        </Typography>
      </Grid>
      <Grid item className={clsx(classes.gridItem)} component="li" xs>
        <Column
          baseAmount={baseAmount}
          baseHeight={baseHeight}
          baseValue={values.diamond}
          className={classes.bronzeColumn}
          value={values.bronze}
        />
        <Typography component="span" variant="srOnly">
          {' '}
          para{' '}
        </Typography>
        <Typography className={clsx(classes.columnText, classes.bronzeColumnText)} component="span">
          Bronze
        </Typography>
        <Typography component="span" variant="srOnly">
          ;
        </Typography>
      </Grid>
      <Grid item className={clsx(classes.gridItem)} component="li" xs>
        <Column
          baseAmount={baseAmount}
          baseHeight={baseHeight}
          baseValue={values.diamond}
          className={classes.silverColumn}
          value={values.silver}
        />
        <Typography component="span" variant="srOnly">
          {' '}
          para{' '}
        </Typography>
        <Typography className={clsx(classes.columnText, classes.silverColumnText)} component="span">
          Prata
        </Typography>
        <Typography component="span" variant="srOnly">
          ;
        </Typography>
      </Grid>
      <Grid item className={clsx(classes.gridItem)} component="li" xs>
        <Column
          baseAmount={baseAmount}
          baseHeight={baseHeight}
          baseValue={values.diamond}
          className={classes.goldColumn}
          value={values.gold}
        />
        <Typography component="span" variant="srOnly">
          {' '}
          para{' '}
        </Typography>
        <Typography className={clsx(classes.columnText, classes.goldColumnText)} component="span">
          Ouro
        </Typography>
        <Typography component="span" variant="srOnly">
          ;
        </Typography>
      </Grid>
      <Grid item className={clsx(classes.gridItem)} component="li" xs>
        <Column
          baseAmount={baseAmount}
          baseHeight={baseHeight}
          baseValue={values.diamond}
          className={classes.diamondColumn}
          value={values.diamond}
        />
        <Typography component="span" variant="srOnly">
          {' '}
          para{' '}
        </Typography>
        <Typography
          className={clsx(classes.columnText, classes.diamondColumnText)}
          component="span">
          Diamante
        </Typography>
        <Typography component="span" variant="srOnly">
          .
        </Typography>
      </Grid>
    </Grid>
  </figure>
)

const EarningsChart = withStyles(styles)(PureEarningsChart)

export default EarningsChart
