import React, {useState} from 'react'
import clsx from 'clsx'

import Hidden from '@material-ui/core/Hidden'
import HomeLink from '../HomeLink'
import MenuButton from './MenuButton'
import MenuButtonLabel from './MenuButtonLabel'
import NavigationDrawer from './NavigationDrawer'
import Toolbar from '@material-ui/core/Toolbar'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  logoContainer: {
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    zIndex: 1,
  },
  menuButtonContainer: {
    display: 'block',
    zIndex: 2,
    paddingLeft: 15,
  },
})

const MobileMenu = ({classes, className, homeUrl, logoImage}) => {
  const [openedMenu, isOpened] = useState(false)

  return (
    <Hidden implementation="css" mdUp={true}>
      <Toolbar disableGutters>
        <div className={clsx(classes.logoContainer, className)}>
          <HomeLink 
            href={homeUrl} 
            isExternal={false}
            data-gtm-category-ga4="landing_header"
            data-gtm-label-ga4="logo"
            data-gtm-main-action-ga4="click"
          >
            {logoImage}
          </HomeLink>
        </div>
        <div className={clsx(classes.menuButtonContainer, className)}>
          <MenuButton
            data-testid="menu-button"
            onClick={() => {
              isOpened(true)
            }}
          />
          <MenuButtonLabel>Menu</MenuButtonLabel>
        </div>
      </Toolbar>
      <NavigationDrawer
        data-testid="navigation-drawer"
        opened={openedMenu}
        onClose={() => {
          isOpened(false)
        }}
      />
    </Hidden>
  )
}

export default withStyles(styles)(MobileMenu)
