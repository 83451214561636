import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Divider from '@material-ui/core/Divider'

import styles from './EarningsCalculator.styles'
import Grid from '@material-ui/core/Grid'
import EarningsChart from '../../components/Chart'
import EarningsChartDouble from '../../components/Chart/PureEarningsChartDouble'
import EarningsCalculatorPercentageMobile from './EarningsCalculatorPercentageMobile'
import {isToggleActive} from '../../../feature-toggle'
import Chart1 from '../../../resources/assets/images/earningscalculator/chart1.svg'
import Chart2 from '../../../resources/assets/images/earningscalculator/chart2.svg'

const valuesForF2FSales = {
  seed: 13,
  bronze: 19,
  silver: 21,
  gold: 23,
  diamond: 24,
  seedFirst: 'R$70.12',
  bronzeFirst: 'R$105,18',
  silverFirst: 'R$105,18',
  goldFirst: 'R$112,20',
  diamondFirst: 'R$122,71',
  seedSecond: 'R$44,78',
  bronzeSecond: 'R$67,15',
  silverSecond: 'R$78,36',
  goldSecond: 'R$85,08',
  diamondSecond: 'R$85,08',
  seedTotal: 'R$114,90',
  bronzeTotal: 'R$172,33',
  silverTotal: 'R$183,54',
  goldTotal: 'R$197,28',
  diamondTotal: 'R$207,79',
}

const valuesForOnlineSales = {
  seed: 20,
  bronze: 30,
  silver: 30,
  gold: 32,
  diamond: 35,
}

const PureEarningsCalculatorResultsMobile = ({classes, tabActive}) => {
  return (
    <Grid direction="column" alignItems="flex-start" className={classes.earningResults} container>
      {tabActive === 0 ? null : (
        <>
          <Grid className={classes.earningsCalculatorInfo} item>
            <span className={classes.earningsCalculatorChartResult}>
              Seu cliente compra esse conjunto por:
            </span>
            <span className={classes.earningsCalculatorChartMoney}>
              <span className={classes.earningsCalculatorChartSign}>R$</span> 350,60
            </span>
          </Grid>
          <Divider classes={{root: classes.earningsCalculatorDivider}} />
        </>
      )}
      <Grid item>
        <p className={classes.earningsCalculatorChartDescription}>
          {tabActive === 0 ? (
            <span>
              Enviando pedidos de Natura e Avon, você realiza a venda desses produtos para seus
              clientes por <b>R$574,44</b> Seus ganhos de acordo ao seu nivel como Consultora são:
            </span>
          ) : (
            'Seus ganhos de acordo com o nível como Consultora são:'
          )}
        </p>
      </Grid>
      <Grid className={classes.chartImage} item>
        {tabActive === 0 ? (
          <EarningsChartDouble
            id="earnings-calculator-results-chart"
            caption="Gráfico demonstrando os ganhos com venda"
            data-testid="earnings-calculator-chart-internet-seed"
            baseHeight={5}
            values={valuesForF2FSales}
          />
        ) : (
          <EarningsChart
            id="earnings-calculator-results-chart"
            caption="Gráfico demonstrando os ganhos com venda"
            data-testid="earnings-calculator-chart-internet-seed"
            baseAmount={350.6}
            baseHeight={85}
            values={valuesForOnlineSales}
          />
        )}
      </Grid>
      {tabActive === 0 ? <EarningsCalculatorPercentageMobile /> : null}
    </Grid>
  )
}

const EarningsCalculatorResultsMobile = withStyles(styles)(PureEarningsCalculatorResultsMobile)

export default EarningsCalculatorResultsMobile
