import React from 'react'

export const schemaOrgJSONLD = [
  {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'Como posso ser Consultora de Beleza Natura e Avon e começar a vender?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'Existem apenas quatro regras básicas para você se tornar uma de nossas Consultoras: 1. Ser maior de 18 anos; 2. Possuir CPF próprio e Documento pessoal com foto (RG ou CNH); 3. Preencher nosso formulário de cadastro. 4. Realizar o seu primeiro pedido. Para se cadastrar, basta seguir as instruções abaixo: Clique em um dos botões “CADASTRE-SE" em nossa página da Consultoria de Beleza. Complete o formulário de cadastro com os dados solicitados Inclua a foto do seu documento pessoal Pronto! Caso o cadastro seja aprovado, você receberá o seu código de Consultora e poderá baixar o Aplicativo Minha Consultoria para realizar o seu primeiro pedido.',
        },
      },
      {
        '@type': 'Question',
        name: 'Qual o valor para me cadastrar como Consultora de Beleza Natura e Avon?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'O seu cadastro é totalmente gratuito! Aqui, você não precisa de um investimento para iniciar. Ao habilitar o seu Espaço Digital Natura, você pode vender para todo o Brasil sem sair de casa, começar a acumular pontos, ativar seu cadastro*, e usufruir de todos os benefícios que a Consultoria de Beleza tem a oferecer. Você também pode optar por fazer um pedido e receber os produtos em casa para vender presencialmente. Basta escolher os seus produtos de, no mínimo, 50 pontos da marca Natura ou de 25 pontos da marca Avon. *Para ativar seu cadastro por meio das vendas do Espaço Digital, é necessário acumular 50 pontos em vendas em até 120 dias (6 ciclos).',
        },
      },
      {
        '@type': 'Question',
        name: 'Nao recebi a confirmacao do meu cadastro. O que fazer?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            '<div><p>Caso nao tenha recebido a confirmacao do seu cadastro, siga os passos abaixo:</p><ol ><li>Acesse <a>https://consultoria.natura.com.br</a>;</li><li>Clique em <strong>Primeiro acesso?</strong>;</li><li>Preencha com seu número de <abbr >CPF</abbr> ou e-mail.</li></ol><p >Feito isso, e so criar sua senha! Caso seja apresentada uma mensagem de <strong>dados invalidos</strong>, seu cadastro não foi concluido.</p><p>Caso precise de ajuda, entre em contato pelo <a href="tel:03007115566">0300 711 55 66</a> (para telefones celulares) ou <a href="tel:08000115566">0800 011 55 66</a> (para telefones fixos).</p><p>O atendimento por telefone e de segunda a sexta, das <time><abbr>8h</abbr></time> às <time><abbr >22h</abbr></time><wbr> e aos sabados das <time><abbr>9h</abbr></time> as <time><abbr>22h</abbr></time>.</p></div>',
        },
      },
      {
        '@type': 'Question',
        name: 'Nao consigo me cadastrar',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            '<div><p>Entre em contato conosco pelos telefones:</p><ul><li><a href="tel:03007628872">0300 711 55 66</a> (para telefones celulares)</li><li><a href="tel:08000115566">0800 011 55 66</a>(para telefones fixos)</li></ul><p>O atendimento por telefone é de segunda a sexta, das <time><abbr>8h</abbr></time> às <time><abbr >22h</abbr></time><wbr> e aos sábados das <time><abbr>9h</abbr></time> às <time><abbr>22h</abbr></time>.</p></div>',
        },
      },
      {
        '@type': 'Question',
        name: 'Qual é o meu percentual de ganhos como Consultora?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            '<div>Seus ganhos dependem do seu nível no Plano de Crescimento. Todas as nossas Consultoras de Beleza Natura e Avon iniciam no nível Semente. Revise a seção “Lucros e Ganhos” e lembre-se que há um percentual de ganho e uma quantidade mínima de pontos diferentes para cada marca. Após concluir o seu cadastro, você também terá acesso a essa informação na revista Minha Consultoria.</div>',
        },
      },
      {
        '@type': 'Question',
        name: 'Qual e a porcentagem de lucro',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            '<div><p>O lucro depende do seu nível no plano de crescimento e por qual ferramenta foi feita a venda. Você pode ganhar até 35% de lucro!</p><p>Saiba mais acessando a seção <a>Lucros e Ganhos</a> nesta página.</p></div>',
        },
      },
      {
        '@type': 'Question',
        name: 'Quais são as formas de pagamento de uma Consultora?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            '<div>Natura e Avon possuem duas modalidades de pagamento para novas Consultoras: Crédito ou Pré-pago. Caso possua crédito aprovado, você poderá pagar o seu primeiro pedido somente após o recebimento dos produtos. Seu limite de crédito pode aumentar para os próximos pedidos, de acordo com o seu comportamento de pagamento. Caso seu cadastro seja aprovado na modalidade Pré-paga, você deverá realizar o pagamento no momento em que colocar o seu primeiro pedido. No próximo ciclo você já terá um limite de crédito, que poderá aumentar de acordo com seu comportamento de pagamento. Consulte sua Gerente de Negócios ou Líder em caso de dúvidas.</div>',
        },
      },
      {
        '@type': 'Question',
        name: 'Ja sou uma Consultora. Como criar o meu Espaco Digital?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            '<div><ol><li>Acesse seu <a><strong><abbr><em>app</em></abbr> Consultoria</strong></a> e clique em <strong>Meu Espaço Digital</strong>;</li><li>Escolha o nome do seu Espaço, confirme seus dados e aceite os termos;</li><li>Pronto! Agora é só acompanhar o <em>status</em> no mesmo local.</li></ol><p>Nossa dica: escolha um endereço simples e fácil de memorizar! E com esse endereço, clientes vão chegar até você.</p></div>',
        },
      },
      {
        '@type': 'Question',
        name: 'Em quanto tempo posso começar a vender?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            '<div><p>Imediatamente! Assim que finalizar o seu cadastro e receber seu código, acesse o site <a href="https://consultoria.natura.com.br" target="__blank">consultoria.natura.com.br</a> para criar sua senha. Com a senha feita, faça login e clique no ícone “Novo Pedido”.</p><br><p>Pronto. Basta incluir os produtos que desejar e começar a lucrar! Ah, depois de fazer o seu Primeiro Pedido, seu Espaço Digital também será aberto para as vendas online!</p><br><p>Dica: acesse o menu Ciclo > Revistas Natura para ter acesso as promoções vigentes e compartilhe a Revista Digital com seus clientes por WhatsApp!</p></div>',
        },
      },
    ],
  },
]
