const styles = theme => ({
  container: {
    padding: '24px 24px 0 24px',
  },
  heroMobileDescriptionBlock: {
    padding: '0 16px',
    width: '100%',
    overflowWrap: 'break-word',
  },
  heroCardHeader: {
    font: 'normal normal medium 20px/30px Roboto',
    letterSpacing: '0px',
    color: '#333333',
    padding: '16px',
    color: '#333333',
    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
    },
  },
  heroCardDescription: {
    font: 'normal normal bold 16px/22px Roboto',
    letterSpacing: '0.24px',
    color: '#333333',
  },
  heroCardObservation: {
    marginTop: '16px',
    font: 'normal normal normal 14px/21px Roboto',
    letterSpacing: '0px',
    color: '#777777',
  },
  heroImageOverlay: {
    '&:after': {
      content: `''`,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundImage: 'linear-gradient(45deg, rgba(0,0,0, 0.5), transparent)',
      width: '100%',
      height: '100%',
    },
  },
  heroButton: {
    width: '100%',
  },
})

export default styles;
