import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'

const styles = theme => ({
  heroButton: {
    marginTop: '24px',
    position: 'relative',
    textTransform: 'uppercase',
    height: '48px',
    lineHeight: '1.14',
    letterSpacing: '1.23px',
    fontWeight: 500,
    borderColor: 'F6BE0F',
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.8em',
    },
  },
})

const CommonButton = ({classes, className, children, ...props}) => (
  <Button
    className={clsx(classes.heroButton, className)}
    color="primary"
    size="medium"
    variant="outlined"
    {...props}>
    {children}
  </Button>
)

export default withStyles(styles)(CommonButton)
