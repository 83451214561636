let menuOptions = [
  {
    id: 'Inicio',
    href: '#',
    label: 'Início',
    gtmLabel: 'menu_home',
  },
  {
    id: 'benefits',
    href: '#vantagens',
    label: 'Vantagens',
    gtmLabel: 'menu_beneficio',
  },
  {
    id: 'earnings',
    href: '#lucros-e-ganhos',
    label: 'Lucros e Ganhos',
    gtmLabel: 'menu_simule_seus_lucros',
  },
  {
    id: 'our-causes',
    href: '#nossos-diferenciais',
    label: 'Nossos Diferenciais',
    gtmLabel: 'menu_nossas_causas',
  },
  {
    id: 'help',
    href: '#ajuda',
    label: 'Ajuda',
    gtmLabel: 'menu_ajuda',
  },
]

export default menuOptions
