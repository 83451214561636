module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://accounts.natura-avon.com/queroserconsultora","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#FAFAFA","description":"Na Consultoria de Beleza Natura e Avon quanto mais você cresce, mais ganhos, mais prêmios e mais desenvolvimento você conquista. Cadastre-se!","dir":"ltr","display":"minimal-ui","icon":"resources/assets/images/consultoria-favicon.ico","lang":"pt-br","localize":[{"lang":"pt-br","description":"Na Consultoria de Beleza Natura e Avon quanto mais você cresce, mais ganhos, mais prêmios e mais desenvolvimento você conquista. Cadastre-se!","name":"Quero ser Consultora Natura","short_name":"Quero ser Consultora","start_url":"./?utm_source=web_app_manifest"}],"name":"Quero ser Consultora Natura","orientation":"any","prefer_related_applications":false,"short_name":"Quero ser Consultora","start_url":"./?utm_source=web_app_manifest","theme_color":"#F4AB34","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b725f98c0d497ab1e9a5f6119a7f73e7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":false,"disableMinification":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
