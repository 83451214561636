import React from 'react'
import {withStyles} from '@material-ui/core/styles'

import SectionHeader from './SectionHeader'
import Container from '@material-ui/core/Container'
import clsx from 'clsx'

const styles = () => ({
  root: {
    clear: 'both',
    paddingTop: '64px',
    '&::before, &::after': {
      content: ' ',
      display: 'table',
      overflow: 'auto',
      zoom: 1,
    },
    '&::after': {
      clear: 'both',
    },
  },
  sectionHeader: {
    marginBottom: '32px',
  },
})

const Section = withStyles(styles)(
  ({children, classes, className, headingTitle, headingSubtitle, id, subHeaderStyle, ...props}) => (
    <section id={id} className={clsx(classes.root, className)} {...props}>
      <Container>
        <header className={classes.sectionHeader}>
          <SectionHeader
            id={id}
            title={headingTitle}
            subTitle={headingSubtitle}
            subHeaderStyle={subHeaderStyle}
          />
        </header>
      </Container>
      {children}
    </section>
  ),
)

export default Section
