import React from 'react'
import Typography from '@material-ui/core/Typography'
import {withStyles} from '@material-ui/core/styles'

const styles = theme => {
  return {
    header: {
      color: theme.palette.text.primary,
      borderLeft: `4px solid #D1030A`,
      paddingLeft: '16px',
      lineHeight: '1.5',
      [theme.breakpoints.only('xs')]: {
        fontSize: '20px',
      },
    },
    subHeader: {
      color: theme.palette.text.primary,
      font: 'normal normal normal 16px/24px Roboto',
      lineHeight: '1.5',
      letterSpacing: '0.51px',
      paddingLeft: '20px',
      marginTop: '14px',
    },
  }
}

export default withStyles(styles)(({id, title, classes, subTitle, subHeaderStyle}) => {
  const rootStyle = subHeaderStyle ? subHeaderStyle : classes.subHeader
  let arraySubtitle = []
  if (subTitle) {
    arraySubtitle = subTitle.split('<br>')
  }
  return (
    <hgroup id={id && `${id}-section-header-group`}>
      <Typography
        id={id && `${id}-section-header-title`}
        classes={{root: classes.header}}
        component="h2"
        data-gtm-identifier="element_text"
        variant="h5">
        {title}
      </Typography>
      {arraySubtitle.lenght !== 0 && (
        <Typography
          id={id && `${id}-section-header-subtitle`}
          classes={{root: rootStyle}}
          component="h3"
          variant="subtitle1">
          {arraySubtitle.map(item => <div>{item}</div>)}
        </Typography>
      )}
    </hgroup>
  )
})
