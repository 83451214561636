import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import React from 'react'
import Question from './Question'
import Answer from './Answer'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import {Hidden} from '@material-ui/core'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import {Add, Remove} from '@material-ui/icons'
import styles from './FAQItem.styles'

const PureFAQItem = ({
  answerExcerpt,
  children,
  classes,
  expanded,
  id,
  index,
  question,
  QuestionProps,
  ...props
}) => {
  const action = expanded ? 'click_fechar' : 'click_abrir'
  const label = `${question}_${action}`

  return (
    <ExpansionPanel
      id={id}
      classes={{root: classes.root, expanded: classes.expanded, rounded: classes.rounded}}
      expanded={expanded}
      square={false}
      {...props}>
      <ExpansionPanelSummary
        aria-label={question}
        classes={{root: classes.expansionPanel}}
        data-gtm-action={action}
        data-gtm-category="landing_ajuda"
        data-gtm-main-action="click"
        data-gtm-label={label}
        data-gtm-position={`${label}_pos${index}`}
        data-gtm-action-ga4={expanded ? 'click_close' : 'click_open'}
        data-gtm-category-ga4="landing_faq"
        data-gtm-main-action-ga4="click"
        data-gtm-label-ga4={question}
        data-gtm-position-ga4={index+1}
        expandIcon={
          expanded ? (
            <Remove
              id={id && `${id}-collapse-button`}
              data-gtm-category="landing_ajuda"
              data-gtm-main-action="click_fechar"
              data-gtm-label={`${question}_icone_fechar`}
              data-gtm-position={`${question}_icone_fechar_pos_${index}`}
            />
          ) : (
            <Add
              id={id && `${id}-expand-button`}
              data-gtm-category="landing_ajuda"
              data-gtm-main-action="click_abrir"
              data-gtm-label={`${question}_icone_abrir`}
              data-gtm-position={`${question}_icone_abrir_pos_${index}`}
            />
          )
        }>
        <dl itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
          <Question
            id={`${id}-header`}
            aria-controls={`${id}-content`}
            component="dt"
            {...QuestionProps}>
            {question}
          </Question>
          <dd itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
            {answerExcerpt &&
              !expanded && (
                <Hidden implementation="css" smDown={true}>
                  <Typography className={classes.answerExcerpt}>{answerExcerpt}</Typography>
                </Hidden>
              )}
          </dd>
        </dl>
      </ExpansionPanelSummary>
      <Answer id={`${id}-content`}>{children}</Answer>
    </ExpansionPanel>
  )
}

const FAQItem = withStyles(styles)(PureFAQItem)

export default FAQItem
