const styles = theme => ({
  containerPaper: {
    padding: '24px 16px',
    background: theme.palette.common.white,
    width: '90%',
  },
  customTabs: {
    width: '100%',
    marginBottom: '16px',
    '& > span': {
      lineHeight: 1.5,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0px',
      '& .MuiTabs-centered': {
        justifyContent: 'center',
        flexFlow: 'column',
        alignItems: 'center',
      },
    },
  },
  earningsCalculatorProducts: {
    marginBottom: '16px',
    textAlign: 'left',
  },
  earningsDescription: {
    width: '400px',
    fontSize: '1rem',
    lineHeight: 1.5,
    letterSpacing: '0.51px',
    color: '#333333',
    textAlign: 'left',
    marginBottom: '24px',
  },
  customTab: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '16px',
    border: '1px solid #9F81FD',
    textTransform: 'capitalize',
    color: '#333333',
    fontWeight: 'normal',
    marginLeft: '16px',
    minHeight: 'auto',
    height: '32px',
    padding: '0px',
    fontSize: '14px',
    maxWidth: '400px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '29px',
      padding: '8px 12px',
      marginLeft: '8px',
      lineHeight: '8px',
      fontSize: '12px',
      width: '100%',
      marginBottom: '8px',
      '& > span': {
        lineHeight: 1.2,
      },
    },
  },
  tabActive: {
    backgroundColor: '#D6CAFA',
    borderRadius: '16px',
    border: '1px solid #9F81FD',
    textTransform: 'capitalize',
    color: '#333333 !important',
    fontWeight: 'normal',
    marginLeft: '16px',
    minHeight: 'auto',
    height: '32px',
    padding: '0px',
    fontSize: '14px',
    maxWidth: '400px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '29px',
      padding: '8px 12px',
      marginLeft: '8px',
      lineHeight: '8px',
      fontSize: '12px',
      width: '100%',
      marginBottom: '8px',
      '& > span': {
        lineHeight: 1.2,
      },
    },
  },
  tabLabel: {
    fontSize: '14px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  customTabIndicator: {
    display: 'none',
  },
  earningResults: {
    marginLeft: '16px',
    marginRight: '16px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
    },
  },
  chartImage: {
    width: '100%',
    marginRight: '18px',
    minHeight: '167px',
  },
  earningsCalculatorInfo: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '24px',
    },
  },
  earningsCalculatorChartResult: {
    textAlign: 'left',
    fontSize: '0.875rem;',
    letterSpacing: '0.22px',
    lineHeight: 1.5,
    marginBottom: 0,
    marginTop: 0,
    color: '#777777',
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.90rem',
    },
  },
  earningsCalculatorChartDescription: {
    marginTop: 0,
    textAlign: 'left',
    fontSize: '0.875rem',
    lineHeight: 1.5,
    maxWidth: '700px',
    color: '#777777',
    marginBottom: '-4px',
  },
  earningsCalculatorChartMoney: {
    fontSize: '1rem',
    fontWeight: 500,
    color: '#333333',
    lineHeight: 1.5,
    letterSpacing: '0.13px',
    marginLeft: '8px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.95rem',
    },
  },
  earningsCalculatorChartSign: {
    fontSize: '0.85rem',
  },
  earningsCalculatorDivider: {
    background: '#bbbbbb',
    width: '100%',
    marginTop: '16px',
    marginBottom: '20px',
  },
  earningsTabDivider: {
    background: '#bbbbbb',
    width: '100%',
    marginTop: '16px',
    marginBottom: '20px',
  },
  containerDesktop: {
    display: 'flex',
  },
})

export default styles
