import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import React from "react"
import RegisterCTA from "../RegisterCTA"

const styles = (theme) => ({
  container: {
    background: theme.palette.common.white,
    width: "375px",
    padding: "24px 32px",
    boxShadow: "0 16px 24px 0 rgba(0, 0, 0, 0.16)",
    zIndex: 1,
    position: "relative",
    marginLeft: "-80px",
  },
  heroCardHeader: {
    font: "normal normal bold 40px/48px Roboto",
    letterSpacing: "0.32px",
    color: "#333333",
  },
  heroCardDescription: {
    marginTop: "16px",
    font: "normal normal bold 20px/30px Roboto",
    letterSpacing: "0.24px",
    color: "#333333",
  },
  heroCardObservation: {
    marginTop: "16px",
    font: "normal normal normal 14px/21px Roboto",
    letterSpacing: "0px",
    color: "#777777",
    position: "relative",
  },
  heroButton: {
    width: "100%",
  },
})

const HeroCard = ({ classes, ...props }) => {
  const callToActionButtonText = "CADASTRE-SE"
  return (
    <Paper className={classes.container} {...props}>
      <Typography className={classes.heroCardHeader} component="h1">
        Transforme sua vida com a Consultoria de Beleza
      </Typography>
      <Typography
        className={classes.heroCardDescription}
        component="p"
        variant="h6"
      >
        Ganhe dinheiro vendendo produtos que voce ama!
      </Typography>
      <RegisterCTA
        id="hero-mobile-cta-button"
        aria-label="Cadastrar"
        className={classes.heroButton}
        data-gtm-category="landing_hero"
        data-gtm-main-action="click"
        data-gtm-label={callToActionButtonText}
        data-gtm-category-ga4="landing_hero"
        data-gtm-main-action-ga4="click"
        data-gtm-label-ga4={callToActionButtonText}
        data-testid="hero-register-cta-button-desktop"
        label={callToActionButtonText}
      />
    </Paper>
  )
}

export default withStyles(styles)(HeroCard)
