import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'

export const benefitImage = graphql`
  fragment benefitImage on File {
    childImageSharp {
      fluid(maxWidth: 240, maxHeight: 192, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`

export const benefitImageQuery = graphql`
  query {
    earningsImage: file(relativePath: {eq: "benefits/loja_online_revista_digital.jpg"}) {
      ...benefitImage
    }
    educationImage: file(relativePath: {eq: "benefits/ganhos_diferenciados.jpg"}) {
      ...benefitImage
    }
    healthImage: file(relativePath: {eq: "benefits/produtos_apaixonantes.jpg"}) {
      ...benefitImage
    }
    acknowledgmentImage: file(relativePath: {eq: "benefits/app_exclusivo.jpg"}) {
      ...benefitImage
    }
    onlineSalesImage: file(relativePath: {eq: "benefits/beneficios.jpg"}) {
      ...benefitImage
    }
  }
`

/**
 * @property earningsImage
 * @property educationImage
 * @property healthImage
 * @property acknowledgmentImage
 * @property onlineSalesImage
 */
const useBenefitImage = () => {
  const data = useStaticQuery(benefitImageQuery)
  return data
}

export default useBenefitImage
