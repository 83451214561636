import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    height: 'auto',
    width: '100%',
    maxWidth: '570px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '800px',
    },
  },
  imageFull: {
    height: 'auto',
    width: '100%',
    maxWidth: '800px',
  }, 
  textImage: {
    font: 'normal normal normal 11px/11px Roboto',
    [theme.breakpoints.down('sm')]: {
      font: 'normal normal normal 10px/10px Roboto',
    },
    marginTop: '16px',
  },
})

const EarningsCalculatorCardMobileLessDescription = ({classes, data}) => (
  <div className={classes.container}>
    <img src={data.image} alt={data.altImage} className={data.imageFull ? classes.imageFull : classes.image} />
    <Typography className={classes.textImage} component="span">
      <span>{data.textImage}</span>
    </Typography>
  </div>
);

export default withStyles(styles)(EarningsCalculatorCardMobileLessDescription)
