import React from 'react'
import EducationImage from '../sections/Benefits/EducationImage'
import HealthImage from '../sections/Benefits/HealthImage'
import SalesImage from '../sections/Benefits/SalesImage'
import EarningImage from '../sections/Benefits/EarningImage'
import RecognitionImage from '../sections/Benefits/RecognitionImage'

export default [
  {
    id: '#educacao',
    title: 'Ganhos diferenciados',
    text:
      'Conquiste mais benefícios e aumente seus ganhos evoluindo de nível (de Semente a Diamante) em um Plano de Crescimento feito para você prosperar. Todas as suas vendas de Natura e Avon somam pontos!',
    img: (
      <EducationImage alt="Duas mulheres jovens em pé conversando olhando uma apostila. A da esquerda é branca e veste blusa vermelha. A da direita é negra e veste blusa branca." />
    ),
    carouselID: 0,
  },
  {
    id: '#saude',
    title: 'Produtos apaixonantes',
    text:
      'Ofereça uma Consultoria de Beleza sempre cheia de novidades e dos produtos inovadores de Natura e Avon, além das opções de Casa & Estilo, que vão encantar seus clientes em qualquer ocasião.',
    img: (
      <HealthImage alt="Mulher branca idosa vestindo uma blusa azul e um homem adulto de blusa social clara sorrindo e olhando um notebook." />
    ),
    carouselID: 1,
  },
  {
    id: '#venda_internet',
    title: 'Benefícios',
    text:
      'Mais de 15 opções de cursos e programas de educação e saúde que contribuem para o desenvolvimento, conhecimento e a qualidade da sua vida e de toda a família.',
    img: (
      <SalesImage alt="Mulher asiática sentada em uma escrivaninha sorrindo e olhando para o celular. Sacolas de papel com as cores da Natura estão em cima da mesa ao seu lado." />
    ),
    isActive: true,
    carouselID: 2,
  },
  {
    id: '#reconhecimento',
    title: 'Aplicativo exclusivo',
    text:
      'Acesse conteúdos para encantar seus clientes, faça a gestão do seu negócio e envie pedidos! Tudo isso na palma da sua mão com um aplicativo exclusivo para Consultoras da Beleza Natura e Avon.',
    img: (
      <RecognitionImage alt="Duas mulheres se abraçando, uma está segurando em uma das mãos pequenas caixas com produtos Natura." />
    ),
    carouselID: 3,
  },
  {
    id: '#ganhos',
    title: 'E muito mais',
    text:
      'Treinamentos exclusivos, venda pelas Redes Socias com a Revista Interativa, atenda clientes de todo o Brasil com sua loja online, você divulga seu Espaço Digital e a Natura entrega para você!',
    img: (
      <EarningImage alt="Mulher branca e loira olha sorrindo para a câmera carregando várias sacolas das cores da Natura." />
    ),
    carouselID: 4,
  },
  
]
