import Section from '../components/Section'
import React from 'react'
import EarningsCalculator from './EarningsCalculator/EarningsCalculator'
import Container from '@material-ui/core/Container'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'

const styles = ({palette}) => ({
  subHeader: {
    color: palette.text.primary,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.51px',
    paddingLeft: '20px',
    marginTop: '14px',
  },
  productImage: {
    display: 'flex',
    alignSelf: 'center',
    width: '240px',
  },
})

const EarningsSection = ({classes, ...props}) => (
  <Section
    headingTitle="Lucros e Ganhos"
    headingSubtitle="Você pode escolher vender Natura, Avon ou ambas as marcas e todas as suas vendas contam para o Plano de Crescimento.<br>Quanto maior o seu nível, maiores os seus ganhos! E tem mais, você pode vender pelas redes sociais e também pelo Espaço Digital Natura.<br>Confira seus ganhos com a venda de produtos Natura e Avon:"
    subHeaderStyle={classes.subHeader}
    {...props}>
    <Container component="article">
      <Grid direction="column" alignItems="center" container>
        <EarningsCalculator />
      </Grid>
    </Container>
  </Section>
)

export default withStyles(styles)(EarningsSection)
