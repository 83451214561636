import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import FacebookIcon from "@material-ui/icons/Facebook";
import HeadsetMicOutlinedIcon from "@material-ui/icons/HeadsetMicOutlined";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import PinterestIcon from "@material-ui/icons/Pinterest";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import clsx from "clsx";
import React from "react";
import { isDesktop, isMobile } from "../../hooks/responsive";
import ExternalLink from "../ExternalLink";
import { styles } from "./index.styles";

export default withStyles(styles)(({ classes }) => {
  return (
    <footer
      id="footer"
      data-gtm-category="landing_footer"
      data-gtm-main-action="view"
      data-gtm-label="bloco_footer"
      data-gtm-category-ga4="landing_footer"
      data-gtm-main-action-ga4="view"
      data-gtm-label-ga4="block_footer"
      className={clsx(classes.footer, isMobile() && classes.footerMobile)}
    >
      <Grid
        container
        direction="row"
        justify="center"
        className={clsx(
          classes.footerGrid,
          isMobile() && classes.footerGridMobile
        )}
      >
        <Container>
          <Grid
            container
            direction={isMobile() ? "column" : null}
            justify={isMobile() ? "center" : null}
            alignItems={isMobile() ? "center" : null}
          >
            <Grid item xs={12} sm={9}>
              <Typography
                variant="button"
                className={clsx(
                  classes.title,
                  isMobile() && classes.titleMobile
                )}
              >
                Veja também
              </Typography>
              <Typography variant="caption" className={classes.links}>
                <List
                  dense
                  classes={isMobile() ? { root: classes.listMobile } : {}}
                >
                  <ListItem disableGutters>
                    <ExternalLink
                      data-testid="resources-external-link"
                      id="resources-external-link"
                      color="inherit"
                      data-gtm-category="landing_footer"
                      data-gtm-label="menu_Guias_e_Manuais"
                      data-gtm-main-action="click"
                      data-gtm-category-ga4="landing_footer"
                      data-gtm-label-ga4="Guias e Manuais"
                      data-gtm-main-action-ga4="click"
                      href="https://static.natura.com.br/static/download/Cartilha_Diretrizes_Consultoria.pdf"
                    >
                      Guias e Manuais
                    </ExternalLink>
                  </ListItem>
                  <ListItem disableGutters>
                    <ExternalLink
                      data-testid="already-consultant-external-link"
                      id="already-consultant-external-link"
                      color="inherit"
                      data-gtm-category="landing_footer"
                      data-gtm-label="menu_Já_sou_Consultora"
                      data-gtm-main-action="click"
                      data-gtm-category-ga4="landing_footer"
                      data-gtm-label-ga4="Já sou Consultora"
                      data-gtm-main-action-ga4="click"
                      href="https://consultoria.natura-avon.com.br/"
                    >
                      Já sou Consultora
                    </ExternalLink>
                  </ListItem>
                  <ListItem disableGutters>
                    <ExternalLink
                      data-testid="online-chat-external-link"
                      id="online-chat-external-link"
                      color="inherit"
                      data-gtm-category="landing_footer"
                      data-gtm-label="menu_Chat_Online"
                      data-gtm-main-action="click"
                      data-gtm-category-ga4="landing_footer"
                      data-gtm-label-ga4="Chat Online"
                      data-gtm-main-action-ga4="click"
                      href="https://webchat.natura.com.br/livechat?lcid=1046&source=site-cadastro"
                    >
                      Chat Online
                    </ExternalLink>
                  </ListItem>
                  <ListItem disableGutters>
                    <ExternalLink
                      data-testid="privacy-policy-external-link"
                      id="privacy-policy-external-link"
                      color="inherit"
                      data-gtm-category="landing_footer"
                      data-gtm-label="menu_Política_de_Privacidade"
                      data-gtm-main-action="click"
                      data-gtm-category-ga4="landing_footer"
                      data-gtm-label-ga4="Política de Privacidade"
                      data-gtm-main-action-ga4="click"
                      href="https://static.rede.natura.net/html/br/07_2023/politica-de-privacidade/Natura_Co_Global_Aviso_para_Consultoras.pdf?iprom_id=politica-de-privacidade-consultores_bannerprincipal&iprom_name=destaque1_politica-consultores_11072023&iprom_creative=pdf_baixe-aqui_politica-de-privacidade&iprom_pos=1"
                    >
                      Política de Privacidade
                    </ExternalLink>
                  </ListItem>
                </List>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                variant="button"
                className={clsx(
                  classes.title,
                  isMobile() && classes.titleMobile
                )}
              >
                <HeadsetMicOutlinedIcon
                  fontSize="small"
                  classes={{ root: classes.headsetIcon }}
                />{" "}
                CENTRAL DE RELACIONAMENTO
              </Typography>
              <Typography variant="caption" className={classes.links}>
                <List
                  classes={{
                    root: isMobile()
                      ? classes.listMobile
                      : classes.listDesktopPhoneList,
                  }}
                >
                  <ListItem>
                    <Link
                      data-testid="0300_phone"
                      href="tel:03007115566"
                      underline={isDesktop() ? "none" : "hover"}
                      color="inherit"
                      component={isDesktop() ? "p" : "a"}
                      data-gtm-category-ga4="landing_footer"
                      data-gtm-label-ga4="phone_0300"
                      data-gtm-main-action-ga4="click"
                    >
                      0300 711 55 66
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      data-testid="0800_phone"
                      color="inherit"
                      underline="none"
                      data-gtm-category-ga4="landing_footer"
                      data-gtm-label-ga4="phone_0800"
                      data-gtm-main-action-ga4="click"
                    >
                      0800 011 55 66
                    </Link>
                  </ListItem>
                </List>
                Siga a <b>Natura</b> em:
                <Grid
                  classes={{ root: classes.socialIconsContainer }}
                  container
                  justify={"space-between"}
                >
                  <ExternalLink
                    data-testid="facebook-external-link"
                    id="facebook-external-link"
                    aria-label="Facebook"
                    color="inherit"
                    data-gtm-category="landing_footer"
                    data-gtm-main-action="click"
                    data-gtm-label="menu_facebook"
                    data-gtm-category-ga4="landing_footer"
                    data-gtm-main-action-ga4="click"
                    data-gtm-label-ga4="natura_facebook"
                    href="https://pt-br.facebook.com/naturabroficial/"
                    title="Natura | Facebook"
                  >
                    <FacebookIcon
                      data-gtm-identifier="button_text"
                      fontSize={"small"}
                      titleAccess="Facebook"
                    />
                  </ExternalLink>
                  <ExternalLink
                    data-testid="instagram-external-link"
                    id="instagram-external-link"
                    aria-label="Instagram"
                    color="inherit"
                    data-gtm-category="landing_footer"
                    data-gtm-main-action="click"
                    data-gtm-label="menu_instagram"
                    data-gtm-category-ga4="landing_footer"
                    data-gtm-main-action-ga4="click"
                    data-gtm-label-ga4="natura_instagram"
                    href="https://www.instagram.com/naturabroficial/"
                    title="Natura (@naturabroficial) • Fotos e vídeos do Instagram"
                  >
                    <InstagramIcon
                      data-gtm-identifier="button_text"
                      fontSize={"small"}
                      titleAccess="Instagram"
                    />
                  </ExternalLink>
                  <ExternalLink
                    data-testid="twitter-external-link"
                    id="twitter-external-link"
                    aria-label="Twitter"
                    color="inherit"
                    data-gtm-category="landing_footer"
                    data-gtm-main-action="click"
                    data-gtm-label="menu_twitter"
                    data-gtm-category-ga4="landing_footer"
                    data-gtm-main-action-ga4="click"
                    data-gtm-label-ga4="natura_twitter"
                    href="https://twitter.com/naturabroficial"
                    title="Nat Natura (@naturabroficial) | Twitter"
                  >
                    <TwitterIcon
                      data-gtm-identifier="button_text"
                      fontSize={"small"}
                      titleAccess="Twitter"
                    />
                  </ExternalLink>
                  <ExternalLink
                    data-testid="youtube-external-link"
                    id="youtube-external-link"
                    aria-label="YouTube"
                    color="inherit"
                    data-gtm-category="landing_footer"
                    data-gtm-main-action="click"
                    data-gtm-label="menu_youtube"
                    data-gtm-category-ga4="landing_footer"
                    data-gtm-main-action-ga4="click"
                    data-gtm-label-ga4="natura_youtube"
                    href="https://www.youtube.com/user/naturabemestarbem"
                    title="naturabroficial - YouTube"
                  >
                    <YouTubeIcon
                      data-gtm-identifier="button_text"
                      fontSize={"small"}
                      titleAccess="YouTube"
                    />
                  </ExternalLink>
                  <ExternalLink
                    data-testid="linkedin-external-link"
                    id="linkedin-external-link"
                    aria-label="LinkedIn"
                    color="inherit"
                    data-gtm-category="landing_footer"
                    data-gtm-main-action="click"
                    data-gtm-label="menu_linkedin"
                    data-gtm-category-ga4="landing_footer"
                    data-gtm-main-action-ga4="click"
                    data-gtm-label-ga4="natura_linkedin"
                    href="https://www.linkedin.com/company/natura/"
                    title="Natura | LinkedIn"
                  >
                    <LinkedInIcon
                      data-gtm-identifier="button_text"
                      fontSize={"small"}
                      titleAccess="LinkedIn"
                    />
                  </ExternalLink>
                  <ExternalLink
                    data-testid="pinterest-external-link"
                    id="pinterest-external-link"
                    aria-label="Pinterest"
                    color="inherit"
                    data-gtm-category="landing_footer"
                    data-gtm-main-action="click"
                    data-gtm-label="menu_pinterest"
                    data-gtm-category-ga4="landing_footer"
                    data-gtm-main-action-ga4="click"
                    data-gtm-label-ga4="natura_pinterest"
                    href="https://br.pinterest.com/naturabroficial/"
                    title="Natura (naturabroficial) no Pinterest"
                  >
                    <PinterestIcon
                      data-gtm-identifier="button_text"
                      fontSize={"small"}
                      titleAccess="Pinterest"
                    />
                  </ExternalLink>
                </Grid>
                Siga a <b>Avon</b> em:
                <Grid
                  classes={{ root: classes.socialIconsContainer }}
                  container
                  justify={"space-between"}
                >
                  <ExternalLink
                    data-testid="facebook-external-link-avon"
                    id="facebook-external-link-avon"
                    aria-label="Facebook"
                    color="inherit"
                    data-gtm-category="landing_footer"
                    data-gtm-main-action="click"
                    data-gtm-label="menu_facebook"
                    data-gtm-category-ga4="landing_footer"
                    data-gtm-main-action-ga4="click"
                    data-gtm-label-ga4="avon_facebook"
                    href="https://pt-br.facebook.com/avonbr/"
                    title="Natura | Facebook"
                  >
                    <FacebookIcon
                      data-gtm-identifier="button_text"
                      fontSize={"small"}
                      titleAccess="Facebook"
                    />
                  </ExternalLink>
                  <ExternalLink
                    data-testid="instagram-external-link-avon"
                    id="instagram-external-link-avon"
                    aria-label="Instagram"
                    color="inherit"
                    data-gtm-category="landing_footer"
                    data-gtm-main-action="click"
                    data-gtm-label="menu_instagram"
                    data-gtm-category-ga4="landing_footer"
                    data-gtm-main-action-ga4="click"
                    data-gtm-label-ga4="avon_instagram"
                    href="https://instagram.com/avonbrasil"
                    title="Natura (@avonbrasil) • Fotos e vídeos do Instagram"
                  >
                    <InstagramIcon
                      data-gtm-identifier="button_text"
                      fontSize={"small"}
                      titleAccess="Instagram"
                    />
                  </ExternalLink>
                  <ExternalLink
                    data-testid="twitter-external-link-avon"
                    id="twitter-external-link-avon"
                    aria-label="Twitter"
                    color="inherit"
                    data-gtm-category="landing_footer"
                    data-gtm-main-action="click"
                    data-gtm-label="menu_twitter"
                    data-gtm-category-ga4="landing_footer"
                    data-gtm-main-action-ga4="click"
                    data-gtm-label-ga4="avon_twitter"
                    href="https://twitter.com/AvonBR"
                    title="(AvonBR) | Twitter"
                  >
                    <TwitterIcon
                      data-gtm-identifier="button_text"
                      fontSize={"small"}
                      titleAccess="Twitter"
                    />
                  </ExternalLink>
                  <ExternalLink
                    data-testid="youtube-external-link-avon"
                    id="youtube-external-link-avon"
                    aria-label="YouTube"
                    color="inherit"
                    data-gtm-category="landing_footer"
                    data-gtm-main-action="click"
                    data-gtm-label="menu_youtube"
                    data-gtm-category-ga4="landing_footer"
                    data-gtm-main-action-ga4="click"
                    data-gtm-label-ga4="avon_youtube"
                    href="https://www.youtube.com/user/avonbr"
                    title="avonbr - YouTube"
                  >
                    <YouTubeIcon
                      data-gtm-identifier="button_text"
                      fontSize={"small"}
                      titleAccess="YouTube"
                    />
                  </ExternalLink>
                  <ExternalLink
                    data-testid="linkedin-external-link-avon"
                    id="linkedin-external-link-avon"
                    aria-label="LinkedIn"
                    color="inherit"
                    data-gtm-category="landing_footer"
                    data-gtm-main-action="click"
                    data-gtm-label="menu_linkedin"
                    data-gtm-category-ga4="landing_footer"
                    data-gtm-main-action-ga4="click"
                    data-gtm-label-ga4="avon_linkedin"
                    href="https://www.linkedin.com/company/avon/"
                    title="avon | LinkedIn"
                  >
                    <LinkedInIcon
                      data-gtm-identifier="button_text"
                      fontSize={"small"}
                      titleAccess="LinkedIn"
                    />
                  </ExternalLink>
                  <ExternalLink
                    data-testid="pinterest-external-link-avon"
                    id="pinterest-external-link-avon"
                    aria-label="Pinterest"
                    color="inherit"
                    data-gtm-category="landing_footer"
                    data-gtm-main-action="click"
                    data-gtm-label="menu_pinterest"
                    href="https://br.pinterest.com/naturabroficial/"
                    title="Natura (naturabroficial) no Pinterest"
                  >
                    <div style={{ widht: "20px" }}> </div>
                  </ExternalLink>
                </Grid>
              </Typography>
            </Grid>
          </Grid>
          <Typography
            variant="caption"
            className={clsx(
              classes.title,
              classes.copyright,
              isMobile() && classes.titleMobile
            )}
          >
            © {new Date().getFullYear()} Natura. Todos os direitos reservados.
          </Typography>
        </Container>
      </Grid>
    </footer>
  );
});
