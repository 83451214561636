import withStyles from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'

import Container from '@material-ui/core/Container'
import FAQItem from '../components/FAQ/FAQItem'
import Section from '../components/Section'

const styles = theme => ({
  root: {},
  container: {
    maxWidth: '712px',
  },
})

const PureHelpSection = ({classes, className, data, ...props}) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const isExpanded = id => expanded === id

  return (
    <Section className={clsx(classes.root, className)} headingTitle="Perguntas Frequentes" {...props}>
      <Container className={classes.container} maxWidth="md">
        {data &&
          data.edges.map((edge, index) => {
            const {body, excerpt, frontmatter: {title}, id} = edge.node
            return (
              <FAQItem
                id={`faq-item-${id}`}
                answerExcerpt={excerpt}
                expanded={isExpanded(id)}
                key={id}
                index={index}
                onChange={handleChange(id)}
                question={title}>
                <MDXRenderer>{body}</MDXRenderer>
              </FAQItem>
            )
          })}
      </Container>
    </Section>
  )
}

const HelpSection = withStyles(styles)(PureHelpSection)

export default HelpSection
