import React from 'react'
import clsx from 'clsx'
import styles from './OurCausesSection.styles'
import useOurCausesImagesData from '../../hooks/ourCausesImages'
import withStyles from '@material-ui/core/styles/withStyles'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Container from '@material-ui/core/Container'
import Image from 'gatsby-image'
import RegisterCTA from '../../components/RegisterCTA'
import Section from '../../components/Section'
import Typography from '@material-ui/core/Typography'

export const PureOurCausesSection = ({classes, className, ...props}) => {
  const callToActionText = 'CLIQUE AQUI PARA SE CADASTRAR'
  const data = useOurCausesImagesData()

  return (
    <Section
      className={clsx(classes.root, className)}
      headingTitle="Nossos Diferenciais"
      headingSubtitle="Nossa Consultoria acredita que o poder da venda por relações é o segredo do sucesso.<br>E com a beleza do encontro de Natura e Avon, você pode chegar ainda mais longe."
      {...props}>
      <Container maxWidth="md">
        <Card classes={{root: classes.card}}>
          <CardMedia classes={{root: clsx(classes.cardMedia, classes.firstCardMedia)}}>
            <Image
              alt="Mulher negra sentada em um tronco de árvore acima de um rio no meio da Amazônia olhando para o horizonte."
              draggable={false}
              fluid={data.amazonImage.childImageSharp.fluid}
            />
          </CardMedia>
          <CardContent>
            <Typography classes={{h3: classes.h3}} component="h3" variant="h3">
              Produtos de duas das marcas mais queridas do Brasil
            </Typography>
            <Typography classes={{paragraph: classes.paragraph}} paragraph>
              Com o encontro das duas marcas, você oferece um portfólio amplo e diversificado com
              mais de 2 mil produtos. São maquiagens para diferentes tipos de pele, opções de
              fragrâncias femininas e masculinas, e produtos de corpo, rosto e cabelos para todos os
              gostos. Além dos itens de Casa & Estilo. Tudo o que seus clientes precisam em uma só
              Consultoria!
            </Typography>
          </CardContent>
        </Card>
        <Card classes={{root: clsx(classes.card, classes.secondCard)}}>
          <CardMedia classes={{root: clsx(classes.cardMedia, classes.secondCardMedia)}}>
            <Image
              alt="Imagem de oceano cuja superfície é feita com sacolas de plástico, dando ilusão de ser um mar, entre elas uma se destaca boiando."
              draggable={false}
              fluid={data.plasticOceanImage.childImageSharp.fluid}
            />
          </CardMedia>
          <CardContent>
            <Typography classes={{h3: classes.h3}} component="h3" variant="h3">
              Vendas por relações
            </Typography>
            <Typography classes={{paragraph: classes.paragraph}} paragraph>
              Mais que vender produtos, oferecer uma Consultoria de Beleza é estar próxima para
              compreender a necessidade de cada cliente e promover a melhor experiência de compra. É
              fazer parte de uma rede de afeto que inspira, acolhe, e apoia a transformação da sua
              vida e do mundo por meio de diversos benefícios. Faça parte desse encontro!
            </Typography>
          </CardContent>
        </Card>
        <Typography classes={{root: classes.callToAction}} align="center" paragraph component="h2">
          <RegisterCTA
            id="our-causes-register-cta-button"
            data-gtm-category="landing_nossas_causas"
            data-gtm-label={callToActionText}
            data-gtm-main-action="click"
            data-gtm-category-ga4="landing_nossos_diferenciais"
            data-gtm-label-ga4={callToActionText}
            data-gtm-main-action-ga4="click"
            data-testid="our-causes-register-cta-button"
            label={callToActionText}
          />
        </Typography>
      </Container>
    </Section>
  )
}

const OurCausesSection = withStyles(styles)(PureOurCausesSection)

export default OurCausesSection
