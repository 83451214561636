import React from "react";
import Image from "gatsby-image";
import { graphql, StaticQuery } from "gatsby";

const logoImageQuery = graphql`
  query {
    logoImage: file(relativePath: { eq: "logo.png" }) {
      ...logoImageSize
    }
  }
`;
const logoImage = (data) => (
  <Image
    alt="Logotipo da Consultoria de Beleza"
    fixed={data.logoImage.childImageSharp.fixed}
  />
);

const LogoStaticQuery = ({ render }) => (
  <StaticQuery render={render} query={logoImageQuery} />
);
const Logo = () => (
  <div style={{ marginBottom: "5px", marginTop: "5px" }}>
    <LogoStaticQuery render={logoImage} />
  </div>
);

export default Logo;

export const logoImageSize = graphql`
  fragment logoImageSize on File {
    childImageSharp {
      fixed(width: 160, height: 42, quality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`;
