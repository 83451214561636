import React from 'react'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'
import naturaLogo from '../../../resources/assets/images/earningscalculator/natura-logo-2x.png'
import avonLogo from '../../../resources/assets/images/earningscalculator/avon-logo-2x.png'

const values = [
  {
    seed: '20%',
    bronze: '30%',
    silver: '30%',
    gold: '32%',
    diamond: '35%',
    color: 'natura',
    logo: naturaLogo,
    altLogo: 'Logo da Natura',
  },
  {
    seed: '20%',
    bronze: '30%',
    silver: '35%',
    gold: '38%',
    diamond: '38%',
    color: 'avon',
    logo: avonLogo,
    altLogo: 'Logo da Avon',
  },
]

const styles = theme => ({
  containerColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '16px',
    width: '100%',
  },
  containerRow: {
    display: 'flex',
  },
  item: {
    height: '30px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avon: {
    color: '#E5004B',
  },
  natura: {
    color: '#D65A09',
    boxShadow: '0px 1px 0px #BBBBBB',
  },
  itemBorder: {
    borderLeft: '1px solid #BBBBBB',
  },
  image: {
    marginLeft: '16px',
    maxWidth: '32px',
    maxHeight: '32px',
  },
  textEarning: {
    textAlign: 'left',
    font: 'normal normal bold 16px/24px Roboto',
    letterSpacing: '0.51px',
    color: '#333333',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
  },
})

const PureEarningsCalculatorPercentageMobile = ({classes, tabActive}) => (
  <div className={classes.containerColumn}>
    {values.map(company => (
      <div className={clsx(classes.containerRow, classes[company.color])}>
        <div className={classes.item}>{company.seed}</div>
        <div className={clsx(classes.item, classes.itemBorder)}>{company.bronze}</div>
        <div className={clsx(classes.item, classes.itemBorder)}>{company.silver}</div>
        <div className={clsx(classes.item, classes.itemBorder)}>{company.gold}</div>
        <div className={clsx(classes.item, classes.itemBorder)}>{company.diamond}</div>
      </div>
    ))}
    <div className={classes.textEarning}>
      % de ganhos:
      <img src={values[0].logo} alt={values[0].altLogo} className={classes.image} />
      <img src={values[1].logo} alt={values[1].altLogo} className={classes.image} />
    </div>
  </div>
)

const EarningsCalculatorPercentageMobile = withStyles(styles)(
  PureEarningsCalculatorPercentageMobile,
)

export default EarningsCalculatorPercentageMobile
