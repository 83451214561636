import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  container: {
    padding: '24px 16px 24px 16px',
    marginLeft: '16px',
  },
  productImage: {
    alignSelf: 'left',
    width: '240px',
  },
  productContent: {
    marginLeft: '18px',
  },
  image: {
    maxWidth: '600px',
  },
  earningsCalculatorProductsTitle: {
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '0.875rem;',
    letterSpacing: '0.22px',
    lineHeight: 1.5,
    marginBottom: 0,
    marginTop: 0,
  },
  earningsCalculatorProductsDescription: {
    marginTop: 0,
    marginBottom: '16px',
    textAlign: 'left',
    fontSize: '0.875rem;',
    lineHeight: 1.5,
  },
  noSpacing: {
    marginBottom: 0,
  },
})

const EarningsCalculatorCardDesktop = ({classes, data }) => (
  <div className={classes.container}>
    <Grid alignContent="center" container>
      <Grid className={classes.productImage} item>
      <img src={data.image} alt={data.altImage} className={classes.image}/>
      </Grid>
    </Grid>
  </div>
)

export default withStyles(styles)(EarningsCalculatorCardDesktop)
